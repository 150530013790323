import { colors } from '../../../config'

const styles = theme => ({
  container: {
    position: 'relative',
    backgroundColor: colors.lightGrey,
    height: `calc(100vh - ${theme.headerHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 15
  },
  backButton: {
    position: 'absolute',
    top: 18,
    left: 0,

    [theme.breakpoints.up('sm')]: {
      top: 12
    }
  },
  selectContainer: {
    width: '90%',
    marginLeft: 'auto',
    padding: '0 15px 15px 15px',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
      margin: '0 auto'
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
      margin: '0 auto'
    }
  }
})

export default styles
