import React from 'react'

import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

import ProductsRouter from './ProductsRouter'

export default (
  <PrivateRoute key='/products' path='/products' component={TimedLogoutComponent(ProductsRouter)} allowOffline />
)
