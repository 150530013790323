import React from 'react'
import PropTypes from 'prop-types'

import Chip from '@material-ui/core/Chip'

const chipTypeStyle = {
  success: {
    color: '#ffffff',
    backgroundColor: '#08B205'
  },
  service: {
    color: 'white',
    backgroundColor: '#1B1B1B',
    fontWeight: 'bold'
  },
  normal: {
    color: '#ffffff',
    backgroundColor: '#1B1B1B'
  },
  info: {
    backgroundColor: '#FFECD3',
    borderColor: '#E5B693'
  }
}

/** Basic chip component - spreads props to [MUI Chip](https://material-ui.com/api/chip/) */
export const CustomChip = ({
  children,
  type,
  variant,
  icon,
  onClick,
  clickable = true,
  style
}) => {
  const customStyle = chipTypeStyle[type]
  return <Chip
    size={`small`}
    label={children}
    icon={icon}
    clickable={clickable}
    onClick={onClick}
    variant={variant}
    style={{ ...customStyle, ...style }}
  />
}

CustomChip.defaultProps = {}

CustomChip.propTypes = {}

export default CustomChip
