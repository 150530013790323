import React from 'react'
import Messenger from '../../../../components/Messenger'
import MessageEditorOptions from './MessageEditorOptions'
import MessageEditorRecipients from './MessageEditorRecipients'
import MessageEditorText from './MessageEditorText'
import { messageContentOptions } from '../../../../config'

const MessageEditorForm = (props) => (
  <Messenger
    {...props}
    renderOptions={() => (
      <MessageEditorOptions {...props} />
    )}
    renderRecipients={() => (
      <MessageEditorRecipients />
    )}
    renderText={() => (
      <MessageEditorText />
    )}
    contentOptions={messageContentOptions}
  />
)

export default MessageEditorForm
