import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Button from '../../../components/Button'
import H2 from '../../../components/H2'
import CustomerRegistrationForm from './CustomerRegistrationForm'
import style from './style'
import { translations } from '../../../config'
import ActionBar from '../../../components/ActionBar'

const CustomerRegistrationScreen = (props) => {
  const {
    customerModeUpdate,
    customerModeOn,
    customerModeOff,
    showPasswordModal,
    submitAction,
    afterSubmit,
    disallowCustomerMode,
    onBackClick,
    classes
  } = props
  return (
    <div>
      <SubHeader
        leftContent={!customerModeUpdate && (
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <H2 value={_.toUpper(translations('Add a new customer'))} />
        )}
      />
      {customerModeUpdate &&
      <ActionBar
        title={translations('Customer Mode')}
        buttonTitle={translations('Exit Customer Mode')}
        onClick={showPasswordModal}
      />}
      <div className={classes.contentContainer}>
        <div className={classes.subHeaderContainer}>
          <p className={classes.requiredFieldsText}>{translations('required fields')}</p>
          <div className={classes.customerModeButtonContainer}>
            {!customerModeUpdate && !disallowCustomerMode &&
              <Button onClick={customerModeOn}
                className={classes.customerModeButton}
                color='primary'>
                  {translations('Customer Mode')}
              </Button>
            }
          </div>
        </div>

        <CustomerRegistrationForm submitAction={submitAction} afterSubmit={afterSubmit} />
      </div>
    </div>
  )
}

export default withStyles(style)(CustomerRegistrationScreen)
