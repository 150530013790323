export const NEXT = 'RA/checkoutFlow/NEXT'
export const START = 'RA/checkoutFlow/START'
export const END = 'RA/checkoutFlow/END'
export const CLEAR = 'RA/checkoutFlow/CLEAR'
export const FAIL = 'RA/checkoutFlow/FAIL'
export const BACK = 'RA/checkoutFlow/BACK'
export const SET_CURRENT = 'RA/checkoutFlow/SET_CURRENT'
export const SET_LOADING = 'RA/checkoutFlow/SET_LOADING'
export const SET_ORDER_NUMBER = 'RA/checkoutFlow/SET_ORDER_NUMBER'
export const FETCH_DELIVERY_OPTIONS = 'RA/checkoutFlow/FETCH_DELIVERY_OPTIONS'
export const SET_PREVIOUS_PATH = 'RA/checkoutFlow/SET_PREVIOUS_PATH'
export const SET_DEFERRED_MODULE = 'RA/checkoutFlow/SET_DEFERRED_MODULE'
export const SET_ORDER_ID = 'RA/checkoutFlow/SET_ORDER_ID'
