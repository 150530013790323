import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import StarBorder from '@material-ui/icons/StarBorder'
import Star from '@material-ui/icons/Star'
import P from '../P'
import { translations } from '../../config'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const DetailText = styled(P)`
  margin: 0 5px;
`

const StarRating = ({ rating, reviewsAmount }) => {
  // create an array with 5 items (all undefined)
  const starsArray = [...Array(5)]

  // map over empty array of 5 items, and create new array using the rating to decide if star should be 1 or 0
  // where 1 indicates the star is filled, and 0 indicates a star outline
  // so will return an array in this format [1, 1, 1, 0, 0]
  const stars = starsArray.map((value, index) => {
    // plus one to index, as it is 0 based
    const x = index + 1
    return x <= rating
  })

  return (
    <Container>
      {stars.map((active) => {
        return active ? <Star /> : <StarBorder />
      })}
      {rating
        ? <DetailText value={`${rating} (${reviewsAmount})`} />
        : <DetailText value={translations('No reviews')} />}
    </Container>
  )
}

StarRating.propTypes = {
  rating: PropTypes.number,
  reviewsAmount: PropTypes.number
}

export default StarRating
