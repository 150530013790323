const styles = theme => ({
  messageOptionsContainer: {
    marginTop: 10,
    [theme.breakpoints.up('lg')]: {
      marginTop: 20
    }
  },
  dropDownTemplatesContainer: {
    marginTop: 10,
    [theme.breakpoints.up('lg')]: {
      marginTop: 20
    }
  },
  dropDownStyle: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 210
    },
    minWidth: 245,
    [theme.breakpoints.up('lg')]: {
      minWidth: 270
    }
  },
  inputInkbar: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 210
    },
    minWidth: 245,
    [theme.breakpoints.up('lg')]: {
      minWidth: 270
    }
  },
  formControl: {
    display: 'block'
  },
  noteContainer: {
    backgroundColor: '#e4e4e4',
    marginLeft: '-16px',
    marginRight: '-16px',
    marginTop: '10px',
    padding: '7px',
  }
})

export default styles
