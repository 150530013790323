import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  results: [],
  total: 0,
  filters: {},
  sharedViewStateByAppointmentId: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_APPOINTMENTS_FRESH:
      return reducers.fetchAppointments(state, action)
    case constants.FETCH_APPOINTMENT:
      return reducers.fetchAppointment(state, action)
    case constants.UPDATE_APPOINTMENTS_FILTERS:
      return reducers.updateAppointmentsFilters(state, action)
    case constants.CREATE_APPOINTMENT:
      return reducers.createAppointment(state, action)
    case constants.UPDATE_APPOINTMENT:
      return reducers.updateAppointment(state, action)
    case constants.CANCEL_APPOINTMENT:
      return reducers.cancelAppointment(state, action)
    default:
      return state
  }
}
