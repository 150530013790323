import formStyle from '../../../components/Form/style'
import logoStyles from '../../../components/MainLogo/style'

const styles = theme => ({
  field: {
    textAlign: 'left',
    maxWidth: '330px',
    width: '100%'
  },
  formContainer: {
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto'
  }
})

export default theme => ({
  ...formStyle,
  ...styles,
  ...logoStyles
})
