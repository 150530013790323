import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { goBack, replace } from 'connected-react-router'
import moment from 'moment'
import styled from 'styled-components'
import _ from 'lodash'

import digitalStoreSdk from '../../../digitalStoreSdk'
import FullScreenProgress from '../../../components/FullScreenLoader/FullScreenLoader'
import { actions as appActions } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as followingActions } from '../../../store/modules/following'
import { actions as calendarsActions, selectors as calendarsSelectors } from '../../../store/modules/calendars'
import { actions as currentOrderActions } from '../../../store/modules/currentOrder'
import { actions as currentAppointmentActions } from '../../../store/modules/currentAppointment'
import { actions as appointmentsActions } from '../../../store/modules/appointments'

import ConsultationCustomerSearch from '../../ConsultationWizard/ConsultationCustomerSearch'

const Block = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
`

const CenterBlock = styled(Block)`
  justify-content: center;
  align-items: center;
`

const AddCustomersScreen = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const currentUserId = useSelector(authSelectors.getActiveUserId)
  const storeCalendars = useSelector(calendarsSelectors.getResults)

  useEffect(() => {
    Promise.all([
      dispatch(followingActions.fetchFollowing()),
      dispatch(calendarsActions.fetchCalendarsFresh())
    ])
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const onCustomerSelected = useCallback((customer) => {
    setIsLoading(true)
    dispatch(appActions.toggleBasket({ isOpen: false }))
    digitalStoreSdk.customers.fetchCustomerDetails({ id: customer.id })
      .then((customer) => {
        const addCustomerPromise = dispatch(currentOrderActions.updateOrder({ customer }))
        const createAppointmentPromise = dispatch(appointmentsActions.createAppointment({
          type: 'CONSULTATION',
          startDateTime: moment().toISOString(),
          status: 'UNKNOWN',
          userId: currentUserId,
          customerId: customer.id,
          calendarId: _.get(storeCalendars, '[0].id')
        }))

        return Promise.all([
          addCustomerPromise,
          createAppointmentPromise
        ])
          .then(([customer, appointment]) => {
            const appointmentId = appointment.id
            dispatch(replace(`/consultations/store/${appointmentId}`))
          })
          .finally(() => {
            setIsLoading(false)
          })
      })
  }, [storeCalendars, currentUserId])

  if (isLoading) {
    return (
      <CenterBlock>
        <FullScreenProgress />
      </CenterBlock>
    )
  }

  return (
    <ConsultationCustomerSearch
      hideBottomBar
      fullScreen
      onCustomerClick={onCustomerSelected}
      onRegisterClick={onCustomerSelected}
      onBackClick={() => {
        dispatch(currentAppointmentActions.clearAppointment())
        dispatch(goBack())
      }}
    />
  )
}

export default AddCustomersScreen
