import { connect } from 'react-redux'
import _ from 'lodash'
import { withHandlers, compose } from 'recompose'

import EmailDisplay from './EmailDisplay'
import { selectors as customerDetailsSelectors } from '../../../../../store/modules/customerDetails'
import { actions as createMessageActions } from '../../../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import analyticsService from '../../../../../services/analyticsService'
import { apps } from '../../../../../config'

const mapStateToProps = (state) => {
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const customer = customerDetailsSelectors.getCustomer(state)

  const isMessagingEnabled = isMessagingAllowedForRole && !customer.anonymised && apps.MESSAGING
  return {
    customer,
    isMessagingEnabled
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onActivityClick: ({ dispatch, customer }) => () => {
      analyticsService.sendCustomEvent({ type: 'newMessageScreen', route: 'customerProfile' })
      const messageOptionsObject = _.pick(customer, ['firstName', 'lastName', 'id', 'email', 'telephone', 'customerMarketingPreferences', 'customerMarketingChannels'])
      dispatch(createMessageActions.updateReceipient(messageOptionsObject))
      dispatch(createMessageActions.updateMessage({ communicationType: 'email' }))
    }
  })
)(EmailDisplay)
