import React, { PureComponent } from 'react'

import modalService from '../../../../services/modalService'
import TopRightButton from '../../../../components/TopRightButton'
import { translations } from '../../../../config'

const getFullName = customerResult => `${customerResult.firstName} ${customerResult.lastName}`

class Follow extends PureComponent {
  render() {
    const { following, onClick } = this.props

    return (
      <TopRightButton
        onClick={onClick}
      >
        {
          following
          ? translations('Unfollow')
          : translations('Follow')
        }
      </TopRightButton>
    )
  }
}

export default Follow