import * as constants from './constants'
import _ from 'lodash'
import digitalStoreSdk from '../../../digitalStoreSdk'

class CalendarsActions {
  fetchCalendarsFresh = (query) => ({
    type: constants.FETCH_CALENDARS_FRESH,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.calendars
        .fetchCalendars({...query, sort: 'name'})
    }
  })
}

export default new CalendarsActions()
