const style = theme => ({
  messagesContainer: {
    margin: '20px 30px',
    [theme.breakpoints.up('sm')]: {
      margin: '20px 60px'
    }
  },
  statusText: {
    fontSize: 19,
    textAlign: 'center',
    marginTop: 30
  }
})

export default style
