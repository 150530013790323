import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose, withState, withHandlers } from 'recompose'
import TitleBar from './TitleBar'
import modalService from '../../../../../services/modalService'
import ActionModalOverlay from '../../../../../components/ActionModalOverlay'
import Filters from './Filters'
import { translations } from '../../../../../config'

const FiltersInModal = ({ setTotalSelectedFilters }) => (
  <ActionModalOverlay actions={[{
    onClick: () => modalService.close(),
    success: true,
    primary: true,
    text: 'Confirm'
  }]}>
    <Fragment>
      <h3 style={{ marginTop: 0 }}>{translations('Filters')}</h3>
      <Filters
        displayAsRows
        onFiltersChange={setTotalSelectedFilters}
      />
    </Fragment>
  </ActionModalOverlay>
)

const enhancer = compose(
  withState('totalSelectedFilters', 'setTotalSelectedFilters', 4),
  withHandlers({
    onFilterButtonClick: ({ setTotalSelectedFilters }) => () => {
      modalService.open({
        component: FiltersInModal,
        hideBottomBar: true,
        inModal: true,
        setTotalSelectedFilters
      })
    }
  })
)

FiltersInModal.propTypes = {
  setTotalSelectedFilters: PropTypes.func.isRequired
}

export default enhancer(TitleBar)
