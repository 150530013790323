import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { translations, scanProductEnabled } from '../../config'
import Button from '../Button'
import FullScreenLoader from '../../components/FullScreenLoader/FullScreenLoader'

import style from './style'

const ScanButton = props => {
  const { onClick, classes, isLoading } = props
  return (
    scanProductEnabled
      ? (
        <Fragment>
          {
            isLoading
              ? <FullScreenLoader />
              : null
          }
          <Button
            onClick={onClick}
            buttonType='primary'
            className={classes.bigButton}
            iconImage={'scanIcon'}
            children={useMediaQuery(theme => theme.breakpoints.down('xs')) ? null : translations('Scan')}
          />
        </Fragment>
      )
      : null
  )
}

export default withStyles(style)(ScanButton)
