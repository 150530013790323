import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import { stringManipulation } from '../../../helpers'

export const getIsInitial = state => !state.roles.status
export const getError = state => state.roles.error
export const getHasError = state => !!state.roles.error
export const getAllRoles = state => (state.roles || { }).roles
export const getIsLoading = state => ((state.roles || { }).status === PENDING)

export const getAllRolesOptions = createSelector([
  getAllRoles
], (roles) => {
  return _.chain(roles)
    .map(role => ({ value: role.id, label: stringManipulation.formatRoleName(role.name) }))
    .value()
})

export const getRoleById = id => createSelector([
  getAllRoles
], roles => {
  if (roles) {
    return roles.find(role => id === role.id)
  }
})
