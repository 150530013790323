import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import style from './style'
import { withStyles } from '@material-ui/core/styles'
import { assets, a11y } from '../../config'

const MainLogo = ({ classes, className }) => (
  <div className={cx(classes.logoContainer, className)}>
    <img className={classes.logo} src={assets.loginLogo} alt={a11y.mainLogoAltText} />
  </div>
)

MainLogo.propTypes = {
  value: PropTypes.any
}

export default withStyles(style)(MainLogo)
