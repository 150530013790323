import { colors } from '../../config'

const style = {
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  header: {
    backgroundColor: colors.whiteText,
    borderBottom: `1px solid ${colors.lightGrey}`
  },
  crossIcon: {
    position: 'absolute',
    right: 15,
    cursor: 'pointer'
  },
  innerContainer: {
    borderBottom: `1px solid ${colors.lightGrey}`,
    padding: 20
  },
  commentsContainer: {
    borderBottom: `1px solid ${colors.lightGrey}`,
    padding: 20,
    flex: 1
  },
  questionText: {
    margin: '5px 0 0 0'
  },
  scrollContainer: {
    overflow: 'auto',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  buttonContainer: {
    height: 70,
    display: 'flex',
    padding: '10px 20px',
    backgroundColor: colors.lightGrey
  },
  button: {
    width: '100%',
    flex: 1
  },
  roundedButton: {
    width: '100%',
    border: `1px solid ${'#B7B7B7'}`
  },
  roundedButtonText: {
    color: colors.text
  }
}

export default style
