import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'

import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as followingSelectors } from '../../../store/modules/following'
import { actions as currentOrderActions } from '../../../store/modules/currentOrder'
import { actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import modalService from '../../../services/modalService'

import ConsultationCustomerSearch from './ConsultationCustomerSearch'
import CustomerRegistrationScreen from '../../Customer/CustomerRegistrationScreen'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  return {
    ...ownProps,
    following: followingSelectors.getFollowing(state),
    registerCustomer: () => {
      modalService.open({
        component: CustomerRegistrationScreen,
        hideBottomBar: true,
        fullScreen: true,
        inModal: true,
        onBackClick: () => {
          dispatch(goBack())
          modalService.close()
        },
        submitAction: (params) => {
          return customerDetailsActions.createCustomer({
            ...params,
            noPush: true
          })
        },
        afterSubmit: (customer) => {
          modalService.close()
          return customer
        }
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ConsultationCustomerSearch)
