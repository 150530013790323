import _ from 'lodash'
import moment from 'moment'

const groupByDate = (items, dateField, listingGroups) => {
  const CURRENT_DATE = moment()
  const TODAY = CURRENT_DATE.clone().startOf('day')
  const TOMORROW = CURRENT_DATE.clone().add(1, 'days').startOf('day')

  const isToday = (mDate) => mDate.isSame(TODAY, 'd')
  const isTomorrow = (mDate) => mDate.isSame(TOMORROW, 'd')
  const isUpcoming = (mDate) => mDate.isAfter(TOMORROW)

  const groupedItems = {
    today: [],
    tomorrow: [],
    upcoming: [],
    expired: []
  }
  if (_.size(items)) {
    _.map(items, (item) => {
      if (item[dateField]) {
        const appointmentDate = moment(item[dateField])
        switch (true) {
          case isToday(appointmentDate):
            groupedItems.today.push({ ...item, group: 'today' })
            break
          case isTomorrow(appointmentDate):
            groupedItems.tomorrow.push({ ...item, group: 'tomorrow' })
            break
          case isUpcoming(appointmentDate):
            groupedItems.upcoming.push({ ...item, group: 'upcoming' })
            break
          default:
            groupedItems.expired.push({ ...item, group: 'expired' })
            break
        }
      }
    })
  }
  return _.map(listingGroups, (value, key) => ({ name: value, items: groupedItems[key] }))
}

export default groupByDate
