import React, { Fragment } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { withStyles } from '@material-ui/core/styles'

import RegistrationButton from '../../../components/RegistrationButton'
import { translations } from '../../../config/translations'
import SearchBar from '../../../components/SearchBar'
import Message from '../../../components/Message'
import List from '../../../components/List'

import style from './style'
import P from '../../../components/P'

const MessagesScreen = ({ messages, searchNextMessages, searchMessages,
  hasMore, query, isLoading, classes, goToMessage, noResults, error }) => {

  return (
    <Fragment>
      <SearchBar
        search={searchMessages}
        query={query}
        isLoading={isLoading}
        placeholder={translations('Search Placeholder Messages')}
        searchBarPosition='fixed'
        doNotClearQuery
      />
      {noResults ? <P value={translations('No results found')} type='textButton' className={classes.statusText} /> : null}
      {error ? <P value={translations('Failed Message Search')} type='textButton' className={classes.statusText} /> : null}
      {messages && messages.length && !error
        ? (
          <InfiniteScroll
            pageStart={0}
            className={classes.messagesContainer}
            loadMore={searchNextMessages}
            hasMore={hasMore}
            initialLoad={false}
            useWindow
          >
            <List
              items={messages}
              renderItem={message => (
                <Message
                  key={message.id}
                  message={message}
                  fromCustomer={message.fromCustomer}
                  truncate
                  showButtons={false}
                  goToMessage={goToMessage}
                />
              )}
              noBorder
              noMargin
            />
          </InfiniteScroll>
        ) : null

      }
      <RegistrationButton to='/messages/new'>
        {translations('Create New Message')}
      </RegistrationButton>
    </Fragment>
  )
}

MessagesScreen.propTypes = {

}

export default withStyles(style)(MessagesScreen)
