import React from 'react'
import MessageEditorScreen from './MessageEditorScreen'
import MessagesScreen from './MessagesScreen'
import MessageDetailsScreen from './MessageDetailsScreen'
import PrivateRoute from '../../components/PrivateRoute'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/messages' path='/messages' exact component={TimedLogoutComponent(MessagesScreen)} />,
  <PrivateRoute key='/messages/new' path='/messages/new' exact component={TimedLogoutComponent(MessageEditorScreen)} />,
  <PrivateRoute key='/messages/:id' path='/messages/:id' exact component={TimedLogoutComponent(MessageDetailsScreen)} />
]
