import passwordPolicyConfig from '../passwordPolicy.config'
import _ from 'lodash'

class PasswordPolicy {
  _applyRequireRule = (password) => {
    return password
  }
  _applyMinLengthRule = (password, rule) => {
    return password && password.length >= rule
  }
  _applyMaxLengthRule = (password, rule) => {
    return password && password.length <= rule
  }
  _applyUppercaseMinCountRule = (password, rule) => {
    const uppercaseCount = password.match(/([A-Z])/g)
    return uppercaseCount && uppercaseCount.length >= rule
  }
  _applyLowercaseMinCountRule = (password, rule) => {
    const lowercaseCount = password.match(/([a-z])/g)
    return lowercaseCount && lowercaseCount.length >= rule
  }
  _applyNumberMinCountRule = (password, rule) => {
    const numberCount = password.match(/([\d])/g)
    return numberCount && numberCount.length >= rule
  }
  _applySpecialCharMinCountRule = (password, rule) => {
    const specialCharCount = password.match(/([\!\?\-])/g)
    return specialCharCount && specialCharCount.length >= rule
  }
  _applyNonRepeatingRule = (password, rule) => {
    const repeatingCharacters = password.match(/(.)\1+/g)
    return !repeatingCharacters
  }
  applyPolicyRule = (options) => {
    switch (options.policyRuleName) {
      case 'REQUIRED':
        return this._applyRequireRule(options.password)
      case 'MIN_LENGTH':
        return this._applyMinLengthRule(options.password, options.policyRule.rule)
      case 'MAX_LENGTH':
        return this._applyMaxLengthRule(options.password, options.policyRule.rule)
      case 'UPPERCASE_MIN_COUNT':
        return this._applyUppercaseMinCountRule(options.password, options.policyRule.rule)
      case 'LOWERCASE_MIN_COUNT':
        return this._applyLowercaseMinCountRule(options.password, options.policyRule.rule)
      case 'NUMBER_MIN_COUNT':
        return this._applyNumberMinCountRule(options.password, options.policyRule.rule)
      case 'SPECIAL_CHAR_MIN_COUNT':
        return this._applySpecialCharMinCountRule(options.password, options.policyRule.rule)
      case 'NON_REPEATING':
        return this._applyNonRepeatingRule(options.password, options.policyRule.rule)
      default:
        return new Error('Unknown policy rule')
    }
  }

  validatePassword = (password) => {
    const isValid = _.chain(passwordPolicyConfig)
      .map((policyRule, policyRuleName) => {
        return {
          policyRule: policyRule,
          policyRuleName: policyRuleName
        }
      })
      .reduce((memo, next) => {
        const policyRule = next.policyRule
        const policyRuleName = next.policyRuleName
        if (!_.isError(memo)) {
          const ruleMatches =
            this.applyPolicyRule({
              policyRuleName: policyRuleName,
              policyRule: policyRule,
              password: password
            })
          return ruleMatches || new Error(policyRule.message)
        } else {
          return memo
        }
      }, true)
      .value()
    return isValid
  }

}

export default new PasswordPolicy()
