// Modified example from here
// https://github.com/ArnoSaine/react-pouchdb

import React, { Suspense } from 'react'
import { PouchDB } from 'react-pouchdb'

import { environment } from '../../config'

import { FullScreenLoader } from '../FullScreenLoader'

const PouchDBHOC = (Component) => ({ tableName, local = false }) => (props) => {
  const couchbaseLocation = local ? tableName : `${environment.CLOUDANT_DATABASE_URL}/${tableName}`
  return (
    <PouchDB name={couchbaseLocation}>
      <Suspense fallback={<FullScreenLoader />}>
        <Component {...props} />
      </Suspense>
    </PouchDB>
  )
}

export default PouchDBHOC
