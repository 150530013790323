import React from 'react'
import PropTypes from 'prop-types'
import Form from '../../../../components/Form'
import FormBody from '../../../../components/Form/FormBody'
import FormError from '../../../../components/Form/FormError'
import FormSubmit from '../../../../components/Form/FormSubmit'
import PersonalDetails from '../../../../components/Form/FormSections/PersonalDetails'
import BottomBar from '../../../../components/BottomBar'
import schema from '../../../../components/Form/commonSchemas/userPersonalDetails'
import RoleAndStores from '../../../Users/UserRegistrationScreen/UserRegistrationForm/RoleAndStores'
import SetPassword from '../../../Users/UserRegistrationScreen/UserRegistrationForm/SetPassword'
import { withStyles } from '@material-ui/core/styles'

import { translations, auth, loginTypes } from '../../../../config'

import { formStyle } from './style'

export const formId = 'user-registration'

const UserRegistrationForm = Form(formId)

const UserRegistrationFormWrapper = ({
  onSubmit,
  error,
  submitting,
  invalid,
  classes,
  isFormValid
}) => {
  // props on the form allow the initial values to be updated
  // const usernameRequired = auth.loginType === loginTypes.USERNAME
  return (
    <div>
      <UserRegistrationForm onSubmit={onSubmit} submitLabel={translations('Create user')} initialValues={{ departmentIds: [] }}>
        <FormBody>
          <div>
            <PersonalDetails
              formId={formId}
              schema={schema}
            />
            <RoleAndStores name={'roleId'} />
            <SetPassword />
          </div>
        </FormBody>
        <FormError />
        <FormSubmit
          ContainerComponent={BottomBar}
          label={translations('Create User')}
          buttonClass={classes.registrationButton}
          disabled={isFormValid}
        />
      </UserRegistrationForm>
    </div>
  )
}

UserRegistrationFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  isFormValid: PropTypes.bool
}

export default withStyles(formStyle)(UserRegistrationFormWrapper)
