import { connect } from 'react-redux'

import { selectors as followingSelectors } from '../../../../../../store/modules/following'

import RecipientsSearchModal from './RecipientsSearchModal'

const mapStateToProps = state => {
  return {
    following: followingSelectors.getFollowingCustomersWithoutAnonymised(state)
  }
}

export default connect(mapStateToProps)(RecipientsSearchModal)
