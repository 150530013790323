import React from 'react'
import CustomerRegistration from '../../Customer/CustomerRegistrationScreen'
import { actions } from '../../../store/modules/currentOrder'

const RegisterCustomer = () => {
  return <CustomerRegistration
    submitAction={actions.createCustomer}
  />
}

export default RegisterCustomer
