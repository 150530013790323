import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'

const AddButton = styled(Button)`
  border: 1px dashed #D1D1D1;
  background-color: #EFEFEF;
  padding: ${props => props.small ? '2rem' : '3rem'};
`

const AddIcon = styled(Add)`
  color: #D1D1D1;
`

const AddContentContentButton = ({ onClick, small }) => {
  return (
    <AddButton onClick={onClick} small={small}>
      <AddIcon />
    </AddButton>  
  )
}

export default AddContentContentButton
