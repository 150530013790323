import React, { useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dropdown from '../../../../../components/Fields/Dropdown'
import Checkbox from '../../../../../components/Fields/Checkbox'
import Button from '../../../../../components/Button'
import H2 from '../../../../../components/H2'
import { translations } from '../../../../../config'
import styles from './style'

const BroadcastScreen = (props) => {
  const {
    classes,
    loggedInUserStoresOptions,
    loggedInUserDepartmentOptions,
    onSubmit,
    setGroup,
    broadcastPermissions,
    initStore,
    initDepartment,
    initGroup,
    fetchDepartments
  } = props

  const [storeIds, setStoreIds] = useState(initStore)
  const [departmentIds, setDepartmentIds] = useState(initDepartment)
  const [toggleAll, setToggleAll] = useState(false)
  const [broadcastGroup, setBroadcastGroup] = useState(initGroup)
  const [allDepartments, setAllDepartments] = useState([])

  const updateGroup = (group, text, store) => {
    const checkExists = group.find(el => el === text)
    if (checkExists) {
      const newstate = group.filter(item => item !== checkExists)
      setBroadcastGroup({
        stores: store ? [...newstate] : [...broadcastGroup.stores],
        departments: store ? [...broadcastGroup.departments] : [...newstate]
      })
    } else {
      setBroadcastGroup({
        stores: store ? [...broadcastGroup.stores, text] : [...broadcastGroup.stores],
        departments: store ? [...broadcastGroup.departments] : [...broadcastGroup.departments, text]
      })
    }
  }

  const formConditions = (type) => {
    switch (type) {
      case 'Stores':
        return toggleAll || (loggedInUserStoresOptions.length === 1 && true)
      case 'Departments':
        return toggleAll
          ? true
          : broadcastPermissions.allDepartments
            ? false
            : loggedInUserDepartmentOptions.length === 1 && true
      case 'Submit':
        return broadcastPermissions.allStores && departmentIds.length
          ? false
          : !toggleAll && !storeIds.length > 0 && true
    }
  }

  const updateDepartments = (e) => {
    const departmentIds = e.target.value
    const departmentText = e.nativeEvent.target.innerText
    updateGroup(broadcastGroup.departments, departmentText, false)
    setDepartmentIds(departmentIds)
  }

  const updateStores = (e) => {
    const label = e.nativeEvent.target.innerText
    const storeIds = e.target.value
    updateGroup(broadcastGroup.stores, label, true)
    setStoreIds(storeIds)
  }

  const updateToggleAll = (e) => {
    if (!toggleAll) {
      setBroadcastGroup('All')
    } else {
      setBroadcastGroup({
        stores: [],
        departments: []
      })
    }
    setToggleAll(!toggleAll)
    setStoreIds([])
    setDepartmentIds([])
  }

  const convertDepartments = (data) => data.map(deparment => ({ 'label': deparment.name, 'value': deparment.id }))

  useEffect(() => {
    fetchDepartments()
      .then(convertDepartments)
      .then(deparments => setAllDepartments(deparments))
      .catch(err => console.log(err))
  }, [])

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.leftContent}>
          <H2 className={classes.contextBoxTitle} value={translations('Broadcast')} />
        </Grid>
        {broadcastPermissions.allStores && <Grid item xs={12} className={classes.rightContent}>
          <FormControl className={classes.checkboxContainer}>
            <Checkbox
              label={translations('All users')}
              value={toggleAll}
              checked={toggleAll}
              onChange={updateToggleAll}
              noErrorTextLabel
              name='CheckAll'
            />
          </FormControl>
        </Grid>}
        <Grid item xs={12} className={classes.leftContent}>
          <FormControl className={classes.dropdownFilter} disabled={formConditions('Stores')}>
            <Dropdown
              label={translations('Stores')}
              value={storeIds}
              options={loggedInUserStoresOptions}
              onChange={updateStores}
              passedProps={{ multiple: true }}
              multiple
              noErrorTextLabel
              name='Stores'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.middleContent}>
          <FormControl className={classes.dropdownFilter} disabled={formConditions('Departments')}>
            <Dropdown
              label={translations('Departments')}
              value={departmentIds}
              options={broadcastPermissions.allDepartments
                ? allDepartments
                : loggedInUserDepartmentOptions
              }
              onChange={updateDepartments}
              passedProps={{ multiple: true }}
              multiple
              noErrorTextLabel
              name='Departments'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.leftContent}>
          <Button
            disabled={formConditions('Submit')}
            color='primary'
            className={classes.selfSend}
            onClick={() => {
              const groupName = toggleAll ? 'All' : broadcastGroup
              onSubmit(
                toggleAll
                  ? { groupName }
                  : { storeIds, departmentIds, groupName })
              setGroup({ groupName })
            }}
            fullWidth={useMediaQuery(theme => theme.breakpoints.up('sm'))}
          >{translations('SUBMIT')}</Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(BroadcastScreen)
