import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import MessageIcon from '@material-ui/icons/Email'
import style from './style'
import { translations } from '../../../../config'
import StatusButton from '../../../../components/StatusButton'

const availabilityStatuses = [
  { status: 'available', label: 'Available', color: 'green' },
  { status: 'unavailable', label: 'Unavailable', color: 'Red' }
]

const UserAvailability = ({ classes, editable, userAvailability, onAvailabilityClick, messageForwardingUser }) => (
  <div className={classes.container}>
    <div className={classes.subContainer}>
      <StatusButton
        disabled={!editable}
        statuses={availabilityStatuses}
        status={userAvailability}
        onClick={onAvailabilityClick}
      />
    </div>
    {userAvailability === 'unavailable' && <div className={classes.subContainer}>
      <MessageIcon className={classes.messageForwardingIcon} />
      {messageForwardingUser ?
        <p className={classes.messageForwardingText}>{`Messages Forwarded To: ${messageForwardingUser}`}</p> :
        <p className={classes.messageForwardingText}>{translations(`Messages not being forwarded`)}</p>
      }
    </div>}
  </div>
)

export default withStyles(style)(UserAvailability)
