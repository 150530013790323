import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import ButtonBase from '@material-ui/core/ButtonBase'
import style from './style'

const defaultStatuses = [
  { status: 'positive', color: 'green', label: 'Positive' },
  { status: 'neutral', color: 'orange', label: 'Neutral' },
  { status: 'negative', color: 'red', label: 'Negative' }
]

const StatusButton = ({ classes, disabled, statuses = defaultStatuses, status: currentStatus, onClick }) => {
  const { status, color, label } = _.find(statuses, s => s.status === currentStatus) || {}
  const buttonLabel = status ? (label ? label : `Unknown Label: ${label}`) : `Unknown Status: ${currentStatus}`

  return (
    <ButtonBase disabled={disabled} onClick={onClick} className={classes.statusButton}>
      <div className={classes.statusButtonIcon} style={{ backgroundColor: color || 'grey' }} />
      <div className={classes.statusButtonLabel}>{`${buttonLabel}${disabled ? '' : ' (Change)'}`}</div>
    </ButtonBase>
  )
}

StatusButton.propTypes = {
  classes: PropTypes.shape({
    statusButton: PropTypes.string,
    statusButtonIcon: PropTypes.string,
    statusButtonLabel: PropTypes.string
  }),
  disabled: PropTypes.bool,
  statuses: PropTypes.array,
  status: PropTypes.string,
  onClick: PropTypes.func
}

export default withStyles(style)(StatusButton)
