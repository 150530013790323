import React from 'react'
import PropTypes from 'prop-types'
import AutocompleteDropdown from './AutocompleteDropdown'
import SelectDropdown from './SelectDropdown'

const Dropdown = props => {
  const { autocomplete } = props

  return (
    autocomplete
    ? <AutocompleteDropdown {...props} />
    : <SelectDropdown {...props} />
  )
}

Dropdown.propTypes = {
  autocomplete: PropTypes.bool
}

export default Dropdown
