import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import Button from '../Button'
import { translations } from '../../config'

const MessageItem = styled.div`
  margin: 0.5rem;
  display: flex;
  justify-content: ${({ isAuthor }) => isAuthor  ? 'flex-end' : 'flex-start'};
`

const MessageBubble = styled.div`
  background-color: #e0e0e0;
  border-radius: 16px;
  max-width: 95%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  flex-direction: column;
`

const MessageText = styled.p`
  width: 100%;
  margin: 0;
`
const NameText = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  width: 100%;
  text-align: ${({ isAuthor }) => isAuthor  ? 'right' : 'left'};
`

const MessagesList = ({ messages, hasPrevPage, onPrevPageClick, userIdentity, prevButtonDisabled, senderName, recipiantName }) => {
  const [mappedMessages, setMappedMessages] = useState([]) 

  useEffect(() => {
    const a = messages.map(item => {
      const { author, dateCreated, type, body } = item
      const isAuthor = _.includes(author, userIdentity)
      const name = isAuthor ? senderName : recipiantName
      return {
        text: body,
        date: dateCreated,
        type,
        isAuthor,
        name
      }
    })

    setMappedMessages(a)
  }, [messages])

  return (
    <Fragment>
      {hasPrevPage && (
        <Button
          onClick={onPrevPageClick}
          fullWidth
          disabled={prevButtonDisabled}
        >
          {translations('Chat - load more button text')}
        </Button>
      )}
      {mappedMessages.map((item, index) => (
        <MessageItem isAuthor={item.isAuthor} key={index}>
          <MessageBubble>
            {item.name && (<NameText isAuthor={item.isAuthor}>{item.name}</NameText>)}
            <MessageText>{item.text}</MessageText>
          </MessageBubble>
        </MessageItem>
      ))}
    </Fragment>
  )
}

MessagesList.propTypes = {
  messages: PropTypes.array.isRequired,
  hasPrevPage: PropTypes.bool.isRequired,
  onPrevPageClick: PropTypes.func.isRequired,
  userIdentity: PropTypes.string,
  prevButtonDisabled: PropTypes.bool,
  /* label to show for messages from the sender */
  senderName: PropTypes.string,
  /* label to show for messages from the recipiant */
  recipiantName:  PropTypes.string
}

export default MessagesList
