import _ from 'lodash'
import { createSelector } from 'reselect'
import { selectors as authSelectors } from '../auth'
import { selectors as userDetailsSelectors } from '../userDetails'
import { selectors as usersSelectors } from '../users'
import { selectors as rolesSelectors } from '../roles'
import { selectors as eventDetailsSelectors } from '../eventDetails'
import { selectors as inspirationDetailsSelectors } from '../inspirationDetails'
import { stringManipulation, getFullName } from '../../../helpers'
import { translations } from '../../../config'

export const getCanUserEdit = createSelector([
  authSelectors.getCurrentUser,
  userDetailsSelectors.getSelectedUser
], (currentUser, selectedUser) => {
  const currentUserValue = _.get(currentUser, 'role.value')
  const selectedUserValue = _.get(selectedUser, 'role.value')
  const selectedUserLoginMethod = _.get(selectedUser, 'loginMethod', 'digitalstore')

  if (selectedUserLoginMethod === 'sso') {
    return false
  }
  // super admins can edit everyone
  // if current user is an admin AND selected user is not a super admin
  if ((currentUserValue === 0) || (currentUserValue === 1 && selectedUserValue !== 0)) {
    return true
  }
  return selectedUser && currentUser && currentUser.role.value < selectedUser.role.value
})

export const getIsUserLoggedInWithDS = createSelector([
  userDetailsSelectors.getSelectedUser
], (selectedUser) => {
  const selectedUserLoginMethod = _.get(selectedUser, 'loginMethod', 'digitalstore')

  if (selectedUserLoginMethod === 'sso') {
    return false
  }

  return true
})

export const getAvailableRolesForUser = createSelector([
  rolesSelectors.getAllRoles,
  authSelectors.getActiveUserValue
], (roles = [], userValue) => {
  return roles.filter(role => (
    userValue === 0 || role.value >= userValue
  ))
})

export const getAvailableRolesForUserOptions = createSelector([
  getAvailableRolesForUser
], (roles) => {
  return _.chain(roles)
    .map(role => ({ value: role.id, label: stringManipulation.formatRoleName(role.name) }))
    .value()
})

export const availableRoleNamesForUser = createSelector([
  getAvailableRolesForUser
], roles => roles.map(role => stringManipulation.formatRoleName(role.name)))

export const getAvailableRoleNameOptionsForUser = createSelector([
  getAvailableRolesForUser
], roles => roles.map(role => ({ value: role.id, label: stringManipulation.formatRoleName(role.name) })))

export const isCurrentUserTempOrSales = createSelector([
  authSelectors.getCurrentUser
], currentUser => {
  return currentUser && (currentUser.role.value === 4 || currentUser.role.value === 5)
})

export const isCurrentUserSelectedUser = createSelector([
  authSelectors.getCurrentUser,
  userDetailsSelectors.getSelectedUser
], (currentUser, selectedUser) => {
  return currentUser && selectedUser && currentUser.id === selectedUser.id
})

export const isCurrentUserAbleToEditPersonalDetails = createSelector([
  userDetailsSelectors.getSelectedUserIsDeactivated,
  isCurrentUserTempOrSales,
  isCurrentUserSelectedUser,
  getCanUserEdit,
  getIsUserLoggedInWithDS
], (selectedUserIsDeactivated, isCurrentUserTempOrSalesValue, isCurrentUserSelectedUserValue, editable, userLoggedInWithDS) => {
  if (!userLoggedInWithDS) return false
  return !selectedUserIsDeactivated && ((!isCurrentUserTempOrSalesValue && isCurrentUserSelectedUserValue) || editable)
})

export const isCurrentUserAbleToEditPassword = createSelector([
  userDetailsSelectors.getSelectedUserIsDeactivated,
  isCurrentUserSelectedUser,
  getCanUserEdit,
  getIsUserLoggedInWithDS
], (selectedUserIsDeactivated, isCurrentUserSelectedUserValue, editable, userLoggedInWithDS) => {
  if (!userLoggedInWithDS) return false
  return !selectedUserIsDeactivated && (isCurrentUserSelectedUserValue || editable)
})

export const isCurrentUserAbleToEditRoleAndStores = createSelector([
  userDetailsSelectors.getSelectedUserValue,
  userDetailsSelectors.getSelectedUserIsDeactivated,
  isCurrentUserSelectedUser,
  getCanUserEdit
], (selectedUserRoleValue, selectedUserIsDeactivated, isCurrentUserSelectedUserValue, editable) => {
  // cannot edit super admin roles
  if (selectedUserRoleValue === 0) {
    return false
  }
  return !selectedUserIsDeactivated && editable
})

export const isCurrentUserAbleToDeactivateUser = createSelector([
  isCurrentUserSelectedUser,
  getCanUserEdit
], (isCurrentUserSelectedUserValue, editable) => {
  return !isCurrentUserSelectedUserValue && editable
})

export const getInspirationBelongsToUser = createSelector([
  authSelectors.getCurrentUser,
  inspirationDetailsSelectors.getResult
], (user, inspiration) => {
  return _.get(user, 'id') === _.get(inspiration, 'createdByUserId')
})

export const getEventBelongsToUser = createSelector([
  authSelectors.getCurrentUser,
  eventDetailsSelectors.getResult
], (user, event) => {
  return _.get(user, 'id') === _.get(event, 'createdByUserId')
})

const optionFromUser = (user) => ({
  label: getFullName(user),
  value: user.id
})
const optionFromMe = (user) => {
  const option = optionFromUser(user)
  option.label = _.template(translations('ME ()'))({ me: option.label })
  return option
}

export const getUserOptionsIncludingCurrentUser = createSelector([
  usersSelectors.getResults,
  authSelectors.getActiveUserId,
  authSelectors.getCurrentUser
], (allUsers, activeUserId, activeUser) => {
  let userOptionGroups = []

  userOptionGroups.push([optionFromMe(activeUser)])

  const activeUserIndex = allUsers.findIndex(user => user.id === activeUserId)
  const hasActiveUser = activeUserIndex > -1

  let otherUsers = [...allUsers]
  if (hasActiveUser) {
    otherUsers.splice(activeUserIndex, 1)
  }
  userOptionGroups.push(
    _.chain(otherUsers)
    .sortBy('username')
    .map(optionFromUser)
    .value()
  )
  return userOptionGroups
})
// TODO: This is being used for demo(mvp).
// The functionality of fetching appointmnets will change and this will need to be removed.
export const getUsersIncludingCurrentUser = createSelector([
  usersSelectors.getResults,
  authSelectors.getActiveUserId,
  authSelectors.getCurrentUser
], (allUsers, activeUserId, activeUser) => {
  const usersGroups = []
  usersGroups.push(activeUser)

  const activeUserIndex = allUsers.findIndex(user => user.id === activeUserId)
  const hasActiveUser = activeUserIndex > -1

  const otherUsers = [...allUsers]
  if (hasActiveUser) {
    otherUsers.splice(activeUserIndex, 1)
  }

  return _.concat(usersGroups, otherUsers)
})
