import { useSelector } from 'react-redux'

import {
  getCustomer,
  getInitialCustomerDetailIsLoading,
  getIsInitialState,
  getInitialCustomerDetailIsLoaded
} from '../store/modules/customerDetails/selectors'

/**
 * @description returns current customer details
 * @returns
 */
export const useCustomer = () => {
  return useSelector(getCustomer)
}

/**
 * @description returns if customer is loading
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getInitialCustomerDetailIsLoading)
}

/**
 * @description returns if customer is loading
 * @returns
 */
export const useIsInitial = () => {
  return useSelector(getIsInitialState)
}

/**
 * @description returns if customer is loading
 * @returns
 */
export const useIsLoaded = () => {
  return useSelector(getInitialCustomerDetailIsLoaded)
}