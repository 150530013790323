import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import ProductsRouter from '../../../../containers/Products/ProductsRouter'
import { ProductCarousel } from '../../../Fields'
import Heading from '../../../Heading'
import style from './style'
import { translations } from '../../../../config'

const ProductPicker = ({
  classes,
  editing,
  formName,
  inspirationsModalComponent: InspirationsModalComponent,
  customerModalComponent: CustomerModalComponent,
  options,
  maxContent
}) => (
  <div className={classes.productPickerContainer}>
    <div className={classes.scroller}>
      <FormControl>
        <Heading className={classes.selectProductHeader}>{translations('Select Content')}</Heading>
        <ProductCarousel
          formName={formName}
          name={'content'}
          editing={editing}
          customerModalComponent={CustomerModalComponent}
          productsModalComponent={ProductsRouter}
          inspirationsModalComponent={InspirationsModalComponent}
          options={options}
          maxContent={maxContent}
        />
      </FormControl>
    </div>
  </div>
)

export default withStyles(style)(ProductPicker)
