import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'

import categoriesActions from './actions'
import * as authSelectors from '../auth/selectors'
import * as categoriesSelectors from '../categories/selectors'

class CategoriesMiddleware {
  loadCategoriesMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    // this is all in the if (...) so that the latter perf expensive predicates
    // are only evaluated if the earlier predicates actually return true
    if (
      // is logged in
      authSelectors.getIsLoggedIn(getState()) &&
      // categories aren't already loaded
      categoriesSelectors.getIsInitial(getState()) &&
      // is routing to a page that needs categories
      action.type === LOCATION_CHANGE &&
      (
        matchPath(action.payload.location.pathname, { path: '/products' }) ||
        matchPath(action.payload.location.pathname, { path: '/customers' }) ||
        matchPath(action.payload.location.pathname, { path: '/retail-analytics' })
      )
    ) {
      dispatch(categoriesActions.fetchAllCategories())
    }
  }
}

export default new CategoriesMiddleware()
