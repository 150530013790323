import { connect } from 'react-redux'
import { change } from 'redux-form'
import _ from 'lodash'
import { selectors as createMessageSelectors, actions as createMessageActions } from '../../../../../store/modules/createMessage'
import { actions as customersActions } from '../../../../../store/modules/customers'
import modalService from '../../../../../services/modalService'
import { MessengerEditorRecipients } from '../../../../../components/Messenger'
import RecipientsSearchModal from './RecipientsSearchModal'

import { formId } from '../../MessageEditorForm'

const mapStateToProps = state => {
  const currentRecipients = createMessageSelectors.getCurrentRecipients(state)

  return {
    currentRecipients,
    searchTypeName: 'customers'
  }
}

const mapDispatchToProps = dispatch => {
  const showSearchRecipientsModal = () => {
    dispatch(customersActions.resetStore())
    modalService.open({
      component: RecipientsSearchModal,
      fullScreen: true,
      onCustomerClick: (customer) => {
        const messageOptionsObject = customer
        dispatch(createMessageActions.updateReceipient(messageOptionsObject))
        if (!customer.telephone) {
          dispatch(change(formId, 'communicationType', 'email'))
        }
        modalService.close()
      },
      onBackClick: () => modalService.close()
    })
  }

  return {
    showSearchRecipientsModal
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessengerEditorRecipients)
