import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import rolesActions from './actions'
import * as rolesSelectors from './selectors'
import * as authSelectors from '../auth/selectors'

class RolesMiddleware {
  loadRolesOnRouteToUserMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToNewUser = action.payload.location.pathname === '/users/new'
      const isRoutingToViewUser = matchPath(action.payload.location.pathname, { path: '/users/:id', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const rolesStoreIsNotLoaded = rolesSelectors.getIsInitial(getState())
      const rolesStoreHasError = rolesSelectors.getHasError(getState())
      if (isLoggedIn && (isRoutingToNewUser || isRoutingToViewUser) && (rolesStoreIsNotLoaded || rolesStoreHasError)) {
        dispatch(rolesActions.fetchAllRoles())
      }
    }
  }
  loadRolesOnRouteToUsersMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToUsers = action.payload.location.pathname === '/users'
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const rolesStoreIsNotLoaded = rolesSelectors.getIsInitial(getState())
      const rolesStoreHasError = rolesSelectors.getHasError(getState())
      if (isLoggedIn && isRoutingToUsers && (rolesStoreIsNotLoaded || rolesStoreHasError)) {
        dispatch(rolesActions.fetchAllRoles())
      }
    }
  }
}

export default new RolesMiddleware()
