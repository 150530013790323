import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'
import InputLabel from '@material-ui/core/InputLabel'

import Form from '../../../../components/Form'

import FormBody from '../../../../components/Form/FormBody'
import FormError from '../../../../components/Form/FormError'
import FormSubmit from '../../../../components/Form/FormSubmit'
import { translations } from '../../../../config'
import { Input, Dropdown } from '../../../../components/Fields'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import { FormControl } from '@material-ui/core'
import H2 from '../../../../components/H2'
import TextContent from '../../../../components/Form/TextContent'
import BottomBar from '../../../../components/BottomBar'

import style from './style'
import UploadImage from '../../../../components/UploadImage'

import * as validators from '../../../../validators'
import P from '../../../../components/P'

export const formId = 'send-feedback'

const SendFeedbackForm = Form(formId)

const smallTextLengthValidator = validators.maxLength(250, 'Text')

const renderTypeSelection = ({ classes, typeOptions }) => {
  if (typeOptions.length === 1) {
    return (
      <div>
        <Field
          name='feedbackType'
          component='input'
          type='hidden'
        />
        <InputLabel>{translations('Feedback Type Label')}</InputLabel>
        <P value={typeOptions[0].label} className={classes.feedbackTypeText} />
      </div>

    )
  }
  return (
    <FormControl className={classes.formControlDropdown}>
      <Dropdown
        options={typeOptions}
        name='feedbackType'
        placeholder={translations('Feedback Type Placeholder')}
        label={translations('Feedback Type Label')}
        validate={validators.required}
      />
    </FormControl>
  )
}

const FeedbackForm = ({ classes, onSubmit, onPhotoAdded, fileValue, onPhotoRemoved, typeOptions, initialValues }) => {
  return (
    <SendFeedbackForm submitLabel={translations('Create Customer')} onSubmit={onSubmit} initialValues={initialValues} >
      <FormBody>
        <ContentBox>
          <ContentBoxHeader>
            <TextContent>
              <H2 value={translations('App Feedback')} />
            </TextContent>
          </ContentBoxHeader>
          <ContentBoxBody>
            {renderTypeSelection({ classes, typeOptions })}
            <FormControl className={classes.formControlText}>
              <Input
                label={`${translations('Your Feedback')}*`}
                name='text'
                type='text'
                multiline
                rows={5}
                rowsMax={5}
                validate={[validators.required, smallTextLengthValidator]}
              />
            </FormControl>
            {/* Bit of a hack to be able to store the blob url and file name in redux */}
            <Field name='file' component='input' type='hidden' />
            <div className={classes.imageButtonContainer}>
              {/* Not a redux form field as i didn't want to tie this to redux form */}
              {fileValue ? <P value={fileValue} className={classes.fileName} /> : null}
              <UploadImage
                webTitle={translations('Add Screenshot')}
                onPhotoAdded={onPhotoAdded}
                onPhotoRemoved={onPhotoRemoved}
              />
            </div>
          </ContentBoxBody>

        </ContentBox>
      </FormBody>
      <FormError />
      <FormSubmit
        ContainerComponent={BottomBar}
        label={translations('Send Feedback')}
        buttonType='primary'
        big
      />
    </SendFeedbackForm>
  )
}

export default withStyles(style)(FeedbackForm)
