import React from 'react'

import Editable from '../Editable'
import { Input } from '../../../components/Fields'
import P from '../../P'

const EditableEmail = Editable({
  input: ({ classes, ...props }) => {
    return (
      <Input {...props} />
    )
  },
  display: ({ label, value }) => (
    <div>
      <P value={label} type='textButton' />
      <P value={value} />
    </div>
  )
})

export default EditableEmail
