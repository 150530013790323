import { Analytics } from '@redant/red-ant-analytics'
import { environment } from '../../config'

let analyticsConfig = {}

if (environment.INGEST_ENVIRONMENT) {
  analyticsConfig.ingest = {
    environment: environment.INGEST_ENVIRONMENT,
    apiKey: environment.INGEST_API_KEY,
    project: environment.INGEST_PROJECT_NAME
  }
}

if (environment.KEEN_PROJECT_ID) {
  analyticsConfig.keen = {
    projectId: environment.KEEN_PROJECT_ID,
    writeKey: environment.KEEN_WRITE_KEY,
    readKey: environment.KEEN_READ_KEY
  }
}

class AnalyticsService {
  analytics = new Analytics(analyticsConfig)

  _analytics = new Promise((resolve, reject) => {
    const startSession = () => {
      return this.analytics
        .startSession()
        .then(() => {
          resolve()
        })
        .catch(error => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              startSession()
            }, 1000)
          })
        })
    }
    startSession()
  })

  userLogin = ({ user }) => {
    return this._analytics
      .then(() => {
        return this.analytics.sendLoginEvent()
      })
  }
  productSearch = ({ query, filters, responses, sort }) => {
    const searchType = 'productSearch'
    return this._analytics
      .then(() => {
        return this.analytics.sendSearchEvent({ searchType, query, filters, responses, sort })
      })
  }
  userSearch = ({ query, filters, responses, sort }) => {
    const searchType = 'userSearch'
    return this._analytics
      .then(() => {
        return this.analytics.sendSearchEvent({ searchType, query, filters, responses, sort })
      })
  }
  customerSearch = ({ query, filters, responses, sort }) => {
    const searchType = 'customerSearch'
    return this._analytics
      .then(() => {
        return this.analytics.sendSearchEvent({ searchType, query, filters, responses, sort })
      })
  }
  sendViewProductEvent = ({ name, url, product }) => {
    return this._analytics
      .then(() => {
        return this.analytics.sendViewProductEvent({ name, url, product })
      })
  }
  sendViewEvent = ({ name, url }) => {
    return this._analytics
      .then(() => {
        return this.analytics.sendViewEvent({ name, url })
      })
  }
  sendCustomEvent = (props) => {
    return this._analytics
      .then(() => {
        return this.analytics.sendCustomEvent(props)
      })
  }
  sendCustomPDPEvent = props => {
    const newProps = {
      ...props,
      group: 'PDP'
    }
    return this.sendCustomEvent(newProps)
  }
  sendAddProductToBasketEvent = ({ quantity, product }) => {
    return this._analytics
      .then(() => {
        return this.analytics.sendAddProductToBasketEvent({ quantity, product })
      })
  }
  sendAddProductToWishlist = ({ product }) => {
    return this._analytics
      .then(() => {
        return this.analytics.sendAddProductToWishlistEvent({ product })
      })
  }
  sendProductRecommendationsEvent = ({ responses }) => {
    return this._analytics
      .then(() => {
        return this.analytics.sendProductRecommendationsEvent({ responses })
      })
  }
  sendSearchSelectedEvent = ({ searchType, query, filters, responses, sort, itemId }) => {
    return this._analytics
      .then(() => {
        return this.analytics.sendSearchSelectedEvent({ searchType, query, filters, responses, sort, itemId })
      })
  }
}

export default new AnalyticsService()
