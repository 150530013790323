import { connect } from 'react-redux'
import ReviewConsultaionPanel from './ReviewConsultaionPanel'

const mapStateToProps = ({ currentOrder }) => {
  const { total } = currentOrder

  return {
    basketTotal: total
  }
}

export default connect(mapStateToProps)(ReviewConsultaionPanel)