import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'
import { replace } from 'connected-react-router'
import BoudoirScreen from './BoudoirScreen'
import { actions as inspirationDetailsActions, selectors as inspirationDetailsSelectors } from '../../../store/modules/inspirationDetails'
import { inspirationDetailsCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { actions as currentOrderActions } from '../../../store/modules/currentOrder'
import ViewGalleriesScreen from '../ViewGalleriesScreen'
import modalService from '../../../services/modalService'

const mapStateToProps = state => {
  const inspiration = inspirationDetailsSelectors.getResult(state)
  const selectedGalleryHasDayNight = inspirationDetailsSelectors.getSelectedGalleryHasDayNight(state)
  const nightImageIndex = inspirationDetailsSelectors.getSelectedGalleryNightIndex(state)
  const dayImageIndex = inspirationDetailsSelectors.getSelectedGalleryDayIndex(state)
  const videoGallery = _.get(inspirationDetailsSelectors.getVideoGalleries(state), '[0].media', [])
  const hasMultipleGalleries = inspirationDetailsSelectors.getHasMultipleGalleries(state)
  const activeGallery = inspirationDetailsSelectors.getActiveGallery(state)

  return {
    isLoading: inspirationDetailsSelectors.getIsLoading(state),
    inspiration,
    inspirationFailedToLoad: inspirationDetailsSelectors.getInspirationFailedToLoad(state),
    selectedGalleryHasDayNight,
    nightImageIndex,
    dayImageIndex,
    messageContent: inspirationDetailsSelectors.getMessageContent(state),
    viewMode: inspirationDetailsSelectors.getViewMode(state),
    videoGallery,
    nextInspiration: inspirationDetailsCombinedSelectors.getNextInspiration(state),
    previousInspiration: inspirationDetailsCombinedSelectors.getPreviousInspiration(state),
    hasMultipleGalleries,
    activeGallery
  }
}

const shouldShowOrientationHelper = () => {
  if (!window.cordova) return false
  return window.innerWidth > window.innerHeight
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['viewMode', 'activeGallery'], ({ viewMode, nightImageIndex, dayImageIndex, activeGallery }) => {
    let index = 0
    if (viewMode === 'day') index = dayImageIndex >= 0 ? dayImageIndex : 0
    if (viewMode === 'night') index = nightImageIndex >= 0 ? nightImageIndex : 0

    const mainImage = activeGallery[index]
    return {
      galleryIndex: index,
      mainImageSrc: mainImage ? mainImage.src : null
    }
  }),
  withState('showOrientationHelper', 'setShowOrientationHelper', false),
  withHandlers({
    handleResize: ({ setShowOrientationHelper }) => () => {
      setShowOrientationHelper(shouldShowOrientationHelper())
    },
    onGallerySelectClick: ({ dispatch }) => () => {
      return modalService.open({
        component: ViewGalleriesScreen,
        zoomModal: true,
        fullScreen: true,
        inModal: true,
        onGalleryClick: (index) => {
          dispatch(inspirationDetailsActions.setActiveGalleryIndex(index))
          modalService.close({ modalIndex: 2 })
        },
        modalIndex: 2
      })
    },
    onNightSelectClick: ({ dispatch }) => () => {
      return dispatch(inspirationDetailsActions.setViewMode('night'))
    },
    onDaySelectClick: ({ dispatch }) => () => {
      return dispatch(inspirationDetailsActions.setViewMode('day'))
    },
    closeModal: ({ dispatch, modalIndex }) => () => {
      modalService.close({ modalIndex })
    },
    onOpenVideoClick: ({ dispatch }) => (src) => {
      return modalService.video({
        modalIndex: 2,
        src
      })
    },
    onClickNavigateToPrevious: ({ onClickNavigateToPrevious, previousInspiration }) => () => {
      onClickNavigateToPrevious && onClickNavigateToPrevious(previousInspiration)
    },
    onClickNavigateToNext: ({ onClickNavigateToNext, nextInspiration }) => () => {
      onClickNavigateToNext && onClickNavigateToNext(nextInspiration)
    }
  })
)(BoudoirScreen)
