import formStyle from '../../../components/Form/style'
import logoStyles from '../../../components/MainLogo/style'

const styles = {
  field: {
    textAlign: 'left',
    maxWidth: '330px',
    width: '100%'
  }
}

export default theme => ({
  ...formStyle,
  ...styles,
  ...logoStyles
})
