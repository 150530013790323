import React, { Component } from 'react'
import moment from 'moment'
import _ from 'lodash'

import { dateFormat } from '../../../config'
import DatePicker from './DatePicker'

class DatePickerContainer extends Component {
  constructor (props) {
    super()
    const formattedValue = moment(props.value, dateFormat).format('YYYY-MM-DD')
    if (moment(formattedValue, 'YYYY-MM-DD', true).isValid()) {
      this.value = formattedValue
    } else {
      this.value = props.value
    }
  }
  onChange = (e) => {
    const { value } = e.target
    const newValue = this.handleChange(value)
    this.props.onChange(newValue)
  }

  onBlur = (e) => {
    const { value } = e.target
    if (this.props.onBlur) {
      this.props.onBlur(this.handleChange(value))
    }
  }

  handleChange = value => {
    this.value = value
    const date = new Date(value)
    const formattedValue = moment(date).format(dateFormat)
    if (moment(formattedValue, dateFormat, true).isValid()) {
      return formattedValue
    } else {
      return null
    }
  }

  clearInput = () => {
    this.value = ''
    this.props.onChange('')
  }

  _generateProps = () => ({
    ...this.props,
    value: this.value,
    onChange: this.onChange,
    onBlur: this.onBlur,
    clearInput: this.clearInput,
    requiresClearButton: _.get(window, 'device.platform') === 'iOS'
  })

  render () {
    const props = this._generateProps()
    return (
      <DatePicker {...props} />
    )
  }
}

export default DatePickerContainer
