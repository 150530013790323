import { connect } from 'react-redux'
import SummaryDetails from './SummaryDetails'

const mapStateToProps = ({ currentOrder }) => {
  const { total } = currentOrder

  return {
    basketTotal: total
  }
}

export default connect(mapStateToProps)(SummaryDetails)
